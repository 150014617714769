<template>
    <div class="mb-4">
        <div class="text-gray-400 title text-left duration-500 pc_show">浏览历史</div>
        <div class="tabs flex items-center mt-4 pc_flex_show">
            <div
                v-for="(item,index) in tabs"
                :key="index"
                :class="tabIndex == index ? 'text-primary' : ''"
                class="mr-8 cursor-pointer font-bold"
                @click="changeTab(index)"
            >{{ item }}</div>
        </div>
        <div class="sm:mt-4 box_margin duration-500" v-if="tabIndex == 0 || tabIndex == 1">
            <div class="table_title duration-500 flex justify-between items-center w-full">
                <div>商品</div>
                <!-- <div class="text-gray-400">全部删除</div> -->
            </div>
            <div class="border_box conent_box duration-500">
                <div v-if="notebooks.length > 0" class="flex items-center flex-wrap w-full">
                    <div
                        style="width:33.33%"
                        class="flex justify-center items-center"
                        v-for="(item,index) in notebooks"
                        :class="index > 2 ? 'mt-3' : ''"
                        :key="index"
                    >
                        <div style="width:95%">
                            <div class="w-full">
                                <img
                                    :src="item.masterUrl"
                                    class="cursor-pointer"
                                    alt
                                    @click="goLink(item.commodityLinks)"
                                />
                                <div
                                    class="pt-2 text-gray-500 cursor-pointer"
                                    @click="delHistory('product', item)"
                                >删除记录</div>
                                <!-- <img src="../../../../assets/images/personal/personal8.png" alt /> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="flex justify-center items-center w-full text-gray-500 null"
                    v-else
                >暂无商品的浏览记录</div>
            </div>
        </div>
        <div class="sm:mt-4 box_margin duration-500" v-if="tabIndex == 0 || tabIndex == 2">
            <div class="table_title duration-500 flex justify-between items-center w-full">
                <div>内容</div>
                <!-- <div class="text-gray-400">全部删除</div> -->
            </div>
            <div class="border_box conent_box flex items-center flex-wrap duration-500">
                <ul class="w-full" v-if="contents.length > 0">
                    <li
                        v-for="item in contents"
                        :key="item.tidingsId"
                        class="flex justify-between items-center w-full"
                    >
                        <div
                            @click="goLink(item.link)"
                            class="cursor-pointer"
                        >{{ item.tidingsName }}</div>
                        <div
                            class="text-gray-400 cursor-pointer"
                            @click="delHistory('content', item)"
                        >删除记录</div>
                    </li>
                </ul>
                <div
                    class="flex justify-center items-center w-full text-gray-500 null"
                    v-else
                >暂无内容的浏览记录</div>
            </div>
        </div>
        <!-- <div class="sm:mt-4 box_margin duration-500" v-if="tabIndex == 0 || tabIndex == 3">
            <div class="table_title duration-500 flex justify-between items-center w-full">
                <div>壁纸</div>
            </div>
            <div class="border_box conent_box flex items-center flex-wrap duration-500">
                <div
                    style="width:33.33%"
                    class="flex justify-center items-center"
                    v-for="item in 3"
                    :class="item > 3 ? 'mt-3' : ''"
                    :key="item"
                >
                    <div style="width:95%">
                        <div class="w-full relative">
                            <img src="../../../../assets/images/personal/personal4.png" alt />
                            <div class="absolute z-10 icon_box">
                                <div class="flex items-center cursor-pointer phone_flex_show">
                                    <img src="../../../../assets/images/serve/collection.png" alt />
                                    <img src="../../../../assets/images/serve/download.png" alt />
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-between mt-2 flex-wrap pc_flex_show">
                            <el-button class="btn">删除记录</el-button>
                            <el-button type="primary" class="btn download">下载壁纸</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabs: ['全部', '笔记本', '内容'],
            tabIndex: 0,
            contents: [],
            notebooks: [],
            wallpapers: []
        };
    },
    created() {
        this.getHistory()
    },
    methods: {
        changeTab(index) {
            this.tabIndex = index
        },
        // 获取浏览历史
        getHistory() {
            this.$request({
                method: "get",
                url: this.$requestPath.browsingHistory,
                data: {}
            }).then(res => {
                console.log("获取浏览历史", res)
                if (res.code == 0) {
                    this.contents = []
                    this.notebooks = []
                    this.wallpapers = []
                    let { contents,
                        notebooks,
                        wallpapers } = res.data
                    this.contents.push(...contents)
                    this.notebooks.push(...notebooks)
                    this.wallpapers.push(...wallpapers)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("获取用户信息", error)
            })
        },
        // 内容跳转
        goLink(link) {
            window.open(link)
        },
        // 删除浏览历史
        delHistory(type, item) {
            this.$request({
                method: "delete",
                url: this.$requestPath.delHistory + '?browseId=' + item.browseId,

            }).then(res => {
                console.log("删除浏览历史", res)
                if (res.code == 0) {

                    let list = []
                    if (type == 'product') {
                        // list = 
                        list = this.notebooks.filter(notebooksItem => {
                            return notebooksItem.browseId != item.browseId
                        })
                        this.notebooks = []
                        this.notebooks.push(...list)
                    } else {
                        list = this.contents.filter(contentsItem => {
                            return contentsItem.browseId != item.browseId
                        })
                        this.contents = []
                        this.contents.push(...list)
                    }
                    this.$EventBus.$emit("toast", { type: 'success', message: '浏览记录删除成功' })
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("获取用户信息", error)
            })
        }
    },
};
</script>

<style lang='scss' scoped>
.border_box {
    border: 0.0625rem solid var(--border-base);
}

@media screen and (max-width: 640px) {
    .img_box {
        width: 4rem;
    }
    .base_right {
        width: calc(100% - 5rem);
        font-size: 0.75rem;
        img {
            width: 0.375rem !important;
        }
        .info_base {
            margin: 0.3125rem 0;
        }
    }
    .base_info {
        padding: 0.625rem 0.25rem;
    }
    .table_title {
        padding: 0.5rem 0;
    }
    .conent_box {
        padding: 0;
        border: none !important;
    }
    .text_left {
        width: 3.125rem;
        display: inline-block;
        text-align: left;
    }
    li {
        font-size: 0.75rem;
    }
    li:not(:last-child) {
        margin-bottom: 0.25rem;
    }
    .pc_show {
        display: none;
    }
    .pc_flex_show {
        display: none;
    }
}
@media screen and (max-width: 900px) {
    .pc_flex_show {
        display: none;
    }
    .box_margin {
        margin-top: 0.625rem;
    }
    .icon_box {
        bottom: 0.25rem;
        right: 0.25rem;
        img {
            width: 0.75rem;
            margin-left: 0.3125rem;
        }
    }
    .phone_flex_show {
        display: flex;
    }

    .title {
        font-size: 1.25rem;
    }
}
@media screen and (min-width: 640px) {
    .pc_show {
        display: block;
    }
    .pc_flex_show {
        display: flex;
    }
    li {
        list-style-type: disc !important;
    }
    li:not(:last-child) {
        margin-bottom: 0.625rem;
    }
    .table_title {
        padding: 0.5rem 0;
    }
    .conent_box {
        padding: 1rem;
    }
    .img_box {
        width: 6.25rem;
    }
    .base_right {
        width: calc(100% - 8rem);
    }
    .info_base {
        margin: 1rem 0;
    }
    .text_left {
        width: 3.75rem;
        display: inline-block;
        text-align: left;
    }
}
@media screen and (min-width: 900px) {
    .pc_flex_show {
        display: flex;
    }
    .btn {
        width: 49%;
        margin: 0 !important;
    }
    .phone_flex_show {
        display: none;
    }

    .title {
        font-size: 1.875rem;
    }
}
</style>